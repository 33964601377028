import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { isPlatformServer } from '@angular/common';
import { Injectable, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { SubscriptionStateEnum } from '@cgib/shared/enums/subscription-state.enum';
import { QuotaService } from '@cgib/ui-shared/services/quota.service';
import { UserService } from '@cgib/ui-shared/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard {
    private readonly platformID = inject(PLATFORM_ID);
    private readonly quotaService = inject(QuotaService);
    private readonly userService = inject(UserService);
    private readonly router = inject(Router);

    canActivate(
        route: ActivatedRouteSnapshot,
        routerStateSnapshot: RouterStateSnapshot
    ): Observable<boolean | UrlTree> {
        if (isPlatformServer(this.platformID)) {
            return of(true);
        }
        return this.userService.isAuthenticated$().pipe(
            take(1),
            switchMap(authenticated => {
                if (authenticated) {
                    if (route.data['requiredScope'] || route.data['allowedSubscriptions']) {
                        return forkJoin([
                            this.userService.getPermissions$().pipe(take(1)),
                            this.quotaService.getQuota$().pipe(take(1))
                        ]).pipe(
                            map(([permissions, quota]) => {
                                if (
                                    route.data['requiredScope'] &&
                                    permissions.length &&
                                    permissions.includes(route.data['requiredScope'])
                                ) {
                                    return true;
                                }
                                if (
                                    route.data['allowedSubscriptions'] &&
                                    quota.activeSubscription?.subscriptionState === SubscriptionStateEnum.ACTIVE &&
                                    quota.activeSubscription.subscriptionPriceId &&
                                    (route.data['allowedSubscriptions'] as string[]).includes(
                                        quota.activeSubscription.subscriptionPriceId
                                    )
                                ) {
                                    return true;
                                }
                                if (route.data['redirect']) {
                                    return this.router.createUrlTree([route.data['redirect']]);
                                }
                                return this.router.createUrlTree(['/home']);
                            })
                        );
                    }
                    return of(true);
                } else {
                    return of(
                        this.router.createUrlTree(['/auth/sign-in'], {
                            queryParams: {
                                redirectUrl: routerStateSnapshot.url
                            }
                        })
                    );
                }
            })
        );
    }
}
