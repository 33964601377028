import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';

import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserService } from '@cgib/ui-shared/services/user.service';

@Injectable({
    providedIn: 'root'
})
export class TosGuard {
    private readonly userService = inject(UserService);
    private readonly router = inject(Router);

    canActivate(_: ActivatedRouteSnapshot, routerStateSnapshot: RouterStateSnapshot): Observable<boolean | UrlTree> {
        return this.userService.isAuthenticated$().pipe(
            take(1),
            switchMap(authenticated => {
                console.log('Autenticated', authenticated);
                if (authenticated) {
                    return this.userService.acceptedTos$().pipe(
                        take(1),
                        map(acceptedTOS => {
                            if (acceptedTOS && acceptedTOS.length) {
                                for (const tos of acceptedTOS) {
                                    if (tos.createdAt > tos.acceptedAt) {
                                        return this.router.createUrlTree(['/profile/tos'], {
                                            queryParams: {
                                                redirectUrl: routerStateSnapshot.url
                                            }
                                        });
                                    }
                                }
                                return true;
                            }

                            return this.router.createUrlTree(['/profile/tos'], {
                                queryParams: {
                                    redirectUrl: routerStateSnapshot.url
                                }
                            });
                        })
                    );
                } else {
                    return of(true);
                }
            })
        );
    }
}
